import { Injector } from "@angular/core";
import { EncryptionService } from "../../../../src/app/services/encryption.service";
import { environment } from "../environments/environment";

const injector = Injector.create({providers: [{provide: EncryptionService, useClass: EncryptionService, deps: []}]});
const encryptionService = injector.get(EncryptionService);

export function projectEncryptData(data: any) {
    const encryptedConfig = encryptionService.encrypt(data, environment.keyEncript, environment.ivEncript);
    return encryptedConfig;
}