import { provideToastr } from 'ngx-toastr';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientProvider } from './services/http-client';
import { AuthService } from './services/auth.service';
import { TokenInterceptorService } from './services/interceptor.service';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { ProjectConfigService } from './services/project-config.service';
import { finalize } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es-ES');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(projectConfigService: ProjectConfigService): () => Promise<any> {
  return (): Promise<any> => projectConfigService.loadConfig();
}

export function appInitializer(userService: AuthService) {
  return () => {
    return new Promise<void>((resolve) => {
      userService.getUserByToken().pipe(
        finalize(() => resolve())
      ).subscribe();
    });
  };
}

export const mainAppConfigProviders: ApplicationConfig['providers'] = [
  { provide: LOCALE_ID, useValue: 'es-ES' },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
  provideZoneChangeDetection({ eventCoalescing: true }),
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [ProjectConfigService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializer,
    deps: [AuthService],
    multi: true
  },
  importProvidersFrom(HttpClientProvider),
  importProvidersFrom(AuthService),
  provideHttpClient(
    withInterceptorsFromDi()
  ),
  provideAnimations(),
  provideToastr({
    toastComponent: CustomToastComponent,
    newestOnTop: false,
    // preventDuplicates: true,
  }),
  provideCharts(withDefaultRegisterables()),
  importProvidersFrom(
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FlatpickrModule.forRoot(),
  ),
];
