import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/account',
        pathMatch: 'full',
    },
    // //* General Pages
    {
        path: '',
        loadComponent: () => import('./shared/component/layout/content/content.component').then((m) => m.ContentComponent),
        loadChildren: () => import('./routes/app.routes'),
    },
    // //* Auth routes 
    {
        path: 'auth',
        loadChildren: () => import('./routes/auth.routes'),
    },
    // //* Error Pages
    {
        path: 'error',
        loadChildren: () => import('./routes/error.routes'),
    },
    {
        path: '**',
        redirectTo : 'error/400',
        pathMatch: 'full',
    },
];
