import { Routes } from '@angular/router';
import { routes as mainRoutes } from '../../../../src/app/main.routes';
import { environment } from '../environments/environment';

export const routes: Routes = [
  {
    path: '',
    redirectTo: environment.authRedirect,
    pathMatch: 'full',
  },
  ...mainRoutes
];
