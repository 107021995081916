import { DeepPartial, AppConfig } from "../../../../src/app/interfaces/app-config";

export const projectConfig: DeepPartial<AppConfig> = {
    // Ejemplo
     pages: {
         login: {
             title: 'Iniciar sesión',
         },
        profile: {
            titlePlural: 'Registros',
            titleSingular: 'Registro',
            form_filter: {
                name:{
                    label: 'Nombre',
                    placeholder: 'Escribir nombre'
                },
                lastName:{
                    label: 'Apellido',
                    placeholder: 'Escribir apellido'
                },
                email:{
                    label: 'Correo electrónico',
                    placeholder: 'Escribir correo electrónico'
                },
                numberCi:{
                    label: 'Numero de carnet',
                    placeholder: 'Escribir numero carnet'
                },
                initial_date:{
                    label: 'Fecha inicio',
                    placeholder: 'Escribir...'
                },
                end_date:{
                    label: 'Fecha fin',
                    placeholder: 'Escribir...'
                },
                city:{
                    label: 'Ciudad',
                    placeholder: 'Escribir...'
                },
                event:{
                    label: 'Eventos',
                    placeholder: 'Seleccionar...'
                },
            }
        },
        historyTickets: {
            titlePlural: 'Tickets',
            titleSingular: 'Ticket',
            form:{},
            form_filter: {
                search: {
                    label: 'Buscar código',
                    placeholder: 'Escribir...'
                },
                name:{
                    label: 'Nombre',
                    placeholder: 'Escribir...'
                },
                lastName:{
                    label: 'Apellido',
                    placeholder: 'Escribir...'
                },
                email:{
                    label: 'Correo electrónico',
                    placeholder: 'Escribir...'
                },
                numberCi:{
                    label: 'Numero de carnet',
                    placeholder: 'Escribir...'
                },
                initial_date:{
                    label: 'Fecha inicio',
                    placeholder: 'Escribir...'
                },
                end_date:{
                    label: 'Fecha fin',
                    placeholder: 'Escribir...'
                },
                event:{
                    label: 'Eventos',
                    placeholder: 'Seleccionar...'
                },
            }
        },
     },

    menu: [
        {
            headTitle1: "General",
            permissions: 'default',
        },
        {
            id: 1,
            level: 1,
            path: '/account',
            title: "Mi Cuenta",
            icon: "home",
            type: "link",
            permissions: 'default',
        },
        {
            headTitle1: "Admin",
            permissions: 'default',
        },
        {
            id: 2,
            level: 1,
            path: '/users',
            title: "Usuarios",
            icon: "bookmark",
            type: "link",
            permissions:'menu-organization-users',
        },
        {
            id: 3,
            level: 1,
            path: '/profile',
            title: "Pre-Registros",
            icon: "bookmark",
            type: "link",
            permissions:'menu-organization-profiles',
        },
        {
            id: 4,
            level: 1,
            path: '/customer',
            title: "customer",
            icon: "bookmark",
            type: "link",
            permissions:'menu-organization-customers',
        },
/*         {
            headTitle1: "Admin tickets",
            permissions: 'menu-organization',
        },
        {
            id: 5,
            level: 1,
            path: 'history-tickets',
            title: "Tickets",
            icon: "bookmark",
            type: "link-external",
            permissions:'menu-organization',
        }, */
        {
            headTitle1: "Admin tickets",
            permissions: 'menu-organization',
        },
        {
            id: 5,
            level: 1,
            path: 'history-tickets',
            title: "Tickets",
            icon: "bookmark",
            type: "link",
            permissions:'page-history-tickets',
        },
        
    ]
};
