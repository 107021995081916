import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    apiUrl: 'https://madness.gateway.test.todotix.net/', //Test
    ticketsUrl: 'http://localhost:61481/', //para dirigir al modulo de tickets
    type: 'nstm',
    title: 'nstm',
    subtitle: 'Membresías',
    description: `"Non-Stop-The-Madness" es un sitio en el que puedes administrar tus membresías, como las de socios, entradas y abonos.`,
    keywords: 'Non-Stop-The-Madness, membresias, socios, abonos, entradas, tickets',
    yearProyect: '2024',
    imgLogoIcon: '/assets/img/isotipo.svg',
    imgLogoIconWhite: '/assets/img/isotipo-white.svg',
    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/isologo.svg',
    imgLogoWhiteSVG: '/assets/img/isologo-white.svg',
    imgLogoWhite: '/assets/img/logo-white.png',
    
    templateType: 'template-1',
    theme: 'light',
    changeTheme: false,

    authGoogle: false,
    authFacebook: false,
    authApple: false,
    authGithub: false,

    authRedirect: '',

    loaderType: 1,
    loaderDefaultText: 'Cargando...',

    //* Firebase
    firebase: {},

    keyEncript: '12345678901234567890123456789012',
    ivEncript: '1234567890123456',
};
