import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { mainAppConfigProviders } from '../../../../src/app/app.config';
import { projectConfig } from './config';
import { projectEncryptData } from './project';

declare global {
  var selfEnv: string;
  var selfPc: string;
}
window['selfEnv'] = projectEncryptData(environment);
window['selfPc'] = projectEncryptData(projectConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    ...mainAppConfigProviders,
  ]
};
