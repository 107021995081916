<div class="toaster">
    <div class="toast-icon">
        <i *ngIf="options.toastClass" class="fa-solid fa-{{ options.toastClass }}"></i>
        <i *ngIf="!options.toastClass" class="fas fa-info-circle"></i>
    </div>
    <div class="toast-details">
        <div>
            <div *ngIf="title"
                [class]="options.titleClass"
                [attr.aria-label]="title">
                {{ title }}
            </div>
            <ng-container *ngIf="isArray(message); else elseMessages">
                <div *ngIf="message" role="alert" aria-live="polite"
                    [class]="options.messageClass">
                    <span class="block w-full" *ngFor="let item of message">{{ item }}</span>
                </div>
            </ng-container>
            <ng-template #elseMessages>
                <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
                    [class]="options.messageClass" [attr.aria-label]="message" [innerHtml]="message">
                </div>
            </ng-template>
        </div>
    </div>

    <div class="close-icon">
        <a *ngIf="options.closeButton" (click)="remove()" class="toast-close-button">
            &times;
        </a>
    </div>
    <div class="progress-content" *ngIf="options.progressBar">
        <div class="toast-progress" [style.width]="width() + '%'"></div>
    </div>
</div>
